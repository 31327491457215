.side-bar{
    display: grid;
    grid-template-rows: 1fr 1fr;
    height: 1050px;
    row-gap:25px;
}
.side {
    /* border: 1px solid red; */
    background: #fff;
    border-radius: 10px;
    padding: 25px 0px 0px 25px;
    box-shadow: 1px 2px 10px 0.7px rgb(247, 247, 247);
    height:500px;
    width: 100%;
}
.side ul{
    list-style: none;
    text-decoration: none;
    text-align: left;
}
.side ul li{
    margin-bottom: 20px;
    cursor: pointer;
  
}
.side ul li span{
    text-align: left;
    text-align: start;
}
.side ul li i{
   font-size: 20px;
   width: 15px;
   color: var(--font-2);
}
.side ul li span{
    margin-left: 35px;
    font-weight: normal;
    color: var(--font-2);
}


.side-btm{
    background: #fff;
    border-radius: 10px;
    padding: 25px 0px 0px 25px;
    box-shadow: 1px 2px 10px 0.7px rgb(247, 247, 247);
    height:500px;
    width: 100%;
    position: relative;
}
.side-btm-m{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.side-btm-m img{
    height:500px;
    width: 100%;
    object-fit: cover;
    bottom: 0;
    border-radius: 10px;
}
.fa-plus{
    display: none;
}

@media screen and (max-width:540px){
    .side-bar{
        position: absolute;
        
        top:100px;
    }
    .side {
        /* border: 1px solid red; */
        padding: 20px 15px 20px 15px;
        box-shadow: none;
        height:400px;
        width: 100%;
        display: none;
        transition: 0.1s ease-in-out;
    }
    .side.active{
        z-index: 10000;
        display: block;
        transition: 0.1s ease-in-out;
    }
    .side ul li{
        margin-bottom: 15px;
    }
    .side ul li i{
       font-size: 16px;
       width: 15px;
       color: var(--font-2);
    }
    .side ul li span{
        margin-left: 10px;
        font-size: 15px;
    }
    .side-btm{
      display: none;
      z-index: -1;
    }

    .fa-plus{
        display: block;
        position: absolute;
        top: -25px;
        padding: 5px;
        background: rgb(154, 116, 231);
        color: #fff;
        text-align: center;
        cursor: pointer;
    }
   
}